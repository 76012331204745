import type {VueformElement} from '@vueform/vueform';

export default function useVueFormCustom(form: any): any {
    function findElementByName(name: string, parent?: object): VueformElement {

        let element: VueformElement;
        // @ts-ignore
        Object.values(parent || form.elements$).every((el: VueformElement) => {
            if (el.name === name) {
                element = el
                return false
            } else if (el.children$) {
                const deeperEl = findElementByName(name, el.children$)
                if (deeperEl) {
                    element = deeperEl
                    return false
                }
            }
            return true
        })
        // @ts-ignore
        return element;
    }

    return {findElementByName}
}
