import { createApp } from "vue";
import App from "./app.vue";
import './assets/main.scss'
import Vueform from '@vueform/vueform'
// @ts-ignore
import {Config} from 'vueform'


createApp(App)
    .use(Vueform, Config)
    .mount("#valuation")

