export default {
  "vueform": {
    "elements": {
      "list": {
        "add": "+ Add",
        "remove": "&times;"
      },
      "file": {
        "defaultName": "File",
        "dndTitle": "Augšupielādē failu",
        "dndDescription": "Ievelc failu vai klikšķini šeit",
        "removeConfirm": "By removing the file it will be permanently deleted. Are you sure to continue?",
        "select": "Select file",
        "upload": "Upload"
      },
      "multifile": {
        "uploadButton": "Upload files",
        "dndTitle": "Upload files",
        "dndDescription": "Drop files or click here to upload"
      },
      "gallery": {
        "uploadButton": "Upload images",
        "dndTitle": "Upload images",
        "dndDescription": "Drop images or click here to upload"
      }
    },
    "steps": {
      "finish": "Finish",
      "next": "Next",
      "previous": "Previous"
    },
    "editor": {
      "acceptedMimesError": "Accepted mimes are: :mimes",
      "acceptedExtensionsError": "Accepted extenions are: :extensions"
    },
    "datepicker": {
      "weekdays": {
        "shorthand": [
          "Sv",
          "Pr",
          "Ot",
          "Tr",
          "Ce",
          "Pk",
          "Se"
        ],
        "longhand": [
          "Svētdiena",
          "Pirmdiena",
          "Otrdiena",
          "Trešdiena",
          "Ceturtdiena",
          "Piektdiena",
          "Sestdiena"
        ]
      },
      "months": {
        "shorthand": [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "Mai",
          "Jūn",
          "Jūl",
          "Aug",
          "Sep",
          "Okt",
          "Nov",
          "Dec"
        ],
        "longhand": [
          "Janvāris",
          "Februāris",
          "Marts",
          "Aprīlis",
          "Maijs",
          "Jūnijs",
          "Jūlijs",
          "Augusts",
          "Septembris",
          "Oktobris",
          "Novembris",
          "Decembris"
        ]
      },
      "daysInMonth": [
        31,
        28,
        31,
        30,
        31,
        30,
        31,
        31,
        30,
        31,
        30,
        31
      ],
      "firstDayOfWeek": 1,
      "ordinal": function (nth) {
        var s = nth % 100;
        if (s > 3 && s < 21) return "th";
        switch (s % 10) {
          case 1:
            return "st";
          case 2:
            return "nd";
          case 3:
            return "rd";
          default:
            return "th";
        }
      },
      "rangeSeparator": " līdz ",
      "weekAbbreviation": "Wk",
      "scrollTitle": "Scroll to increment",
      "toggleTitle": "Click to toggle",
      "amPM": [
        "AM",
        "PM"
      ],
      "yearAriaLabel": "Gads",
      "monthAriaLabel": "Mēnesis",
      "hourAriaLabel": "Stunda",
      "minuteAriaLabel": "Minūte"
    },
    "dateFormats": {
      "datetimeSeconds24": "YYYY-MM-DD HH:mm:ss",
      "datetimeSeconds12": "YYYY-MM-DD hh:mm:ss a",
      "datetime24": "YYYY-MM-DD HH:mm",
      "datetime12": "YYYY-MM-DD hh:mm a",
      "timeSeconds24": "HH:mm:ss",
      "timeSeconds12": "hh:mm:ss a",
      "time24": "HH:mm",
      "time12": "hh:mm a",
      "date": "YYYY-MM-DD"
    },
    "multiselect": {
      "multipleLabelOne": "1 option selected",
      "multipleLabelMore": ":options options selected",
      "noResults": "No options found",
      "noOptions": "Lauks list is empty"
    },
    "defaultMessage": "Invalid field",
    "a11y": {
      "file": {
        "description": "Press Backspace to remove"
      },
      "list": {
        "remove": "Remove item button"
      }
    }
  },
  "validation": {
    "accepted": "Lauks \":attribute\" must be accepted.",
    "active_url": "Lauks \":attribute\" is not a valid URL.",
    "after": "Lauks \":attribute\" must be a date after :date.",
    "after_or_equal": "Lauks \":attribute\" must be a date after or equal to :date.",
    "alpha": "Lauks \":attribute\" may only contain letters.",
    "alpha_dash": "Lauks \":attribute\" may only contain letters, numbers, dashes and underscores.",
    "alpha_num": "Lauks \":attribute\" may only contain letters and numbers.",
    "array": "Lauks \":attribute\" must be an array.",
    "before": "Lauks \":attribute\" must be a date before :date.",
    "before_or_equal": "Lauks \":attribute\" must be a date before or equal to :date.",
    "between": {
      "numeric": "Lauks \":attribute\" must be between :min and :max.",
      "file": "Lauks \":attribute\" must be between :min and :max kilobytes.",
      "string": "Lauks \":attribute\" must be between :min and :max characters.",
      "array": "Lauks \":attribute\" must have between :min and :max items."
    },
    "boolean": "Lauks \":attribute\" field must be true or false.",
    "confirmed": "Lauks \":attribute\" confirmation does not match.",
    "date": "Lauks \":attribute\" is not a valid date.",
    "date_format": "Lauks \":attribute\" does not match the format :format.",
    "date_equals": "Lauks \":attribute\" must be equal to :date.",
    "different": "Lauks \":attribute\" and :other must be different.",
    "digits": "Laukam \":attribute\" jābūt :digits ciparus garam",
    "digits_between": "Lauks \":attribute\" must be between :min and :max digits.",
    "dimensions": "Lauks \":attribute\" has invalid image dimensions.",
    "distinct": "Lauks \":attribute\" field has a duplicate value.",
    "email": "Laukam \":attribute\" ir jābūt derīgai e-pasta adresei",
    "exists": "Lauks selected \":attribute\" is invalid.",
    "file": "Lauks \":attribute\" must be a file.",
    "filled": "Lauks \":attribute\" field must have a value.",
    "gt": {
      "numeric": "Lauks \":attribute\" must be greater than :value.",
      "file": "Lauks \":attribute\" must be greater than :value kilobytes.",
      "string": "Lauks \":attribute\" must be greater than :value characters.",
      "array": "Lauks \":attribute\" must have more than :value items."
    },
    "gte": {
      "numeric": "Lauks \":attribute\" must be greater than or equal :value.",
      "file": "Lauks \":attribute\" must be greater than or equal :value kilobytes.",
      "string": "Lauks \":attribute\" must be greater than or equal :value characters.",
      "array": "Lauks \":attribute\" must have :value items or more."
    },
    "image": "Lauks \":attribute\" must be an image.",
    "in": "Lauks selected \":attribute\" is invalid.",
    "in_array": "Lauks \":attribute\" field does not exist in :other.",
    "integer": "Laukam \":attribute\" jābūt vesalam skaitlim.",
    "ip": "Lauks \":attribute\" must be a valid IP address.",
    "ipv4": "Lauks \":attribute\" must be a valid IPv4 address.",
    "ipv6": "Lauks \":attribute\" must be a valid IPv6 address.",
    "json": "Lauks \":attribute\" must be a valid JSON string.",
    "lt": {
      "numeric": "Lauks \":attribute\" must be less than :value.",
      "file": "Lauks \":attribute\" must be less than :value kilobytes.",
      "string": "Lauks \":attribute\" must be less than :value characters.",
      "array": "Lauks \":attribute\" must have less than :value items."
    },
    "lte": {
      "numeric": "Lauks \":attribute\" must be less than or equal :value.",
      "file": "Lauks \":attribute\" must be less than or equal :value kilobytes.",
      "string": "Lauks \":attribute\" must be less than or equal :value characters.",
      "array": "Lauks \":attribute\" must not have more than :value items."
    },
    "max": {
      "numeric": "Lauks \":attribute\" may not be greater than :max.",
      "file": "Lauka \":attribute\" fails nevar būt lielāks par :max kb.",
      "string": "Lauks \":attribute\" may not be greater than :max characters.",
      "array": "Lauks \":attribute\" may not have more than :max items."
    },
    "mimes": "Lauka \":attribute\" faila tipam jābūt: :values.",
    "mimetypes": "Lauka \":attribute\" faila tipam jābūt: :values.",
    "min": {
      "numeric": "Laukam \":attribute\" ir jābūt vismaz :min",
      "file": "Lauks \":attribute\" must be at least :min kilobytes.",
      "string": "Laukam \":attribute\" ir jābūt vismaz :min simbolus garam",
      "array": "Laukā \":attribute\" izvēlēto vienību skaitam jābūt vismaz :min."
    },
    "not_in": "Lauks selected \":attribute\" is invalid.",
    "not_regex": "Lauks \":attribute\" format is invalid.",
    "numeric": "Laukam \":attribute\" ir jābūt skaitlim",
    "present": "Lauks \":attribute\" field must be present.",
    "regex": "Lauks \":attribute\" format is invalid.",
    "required": "Lauks \":attribute\" ir obligāts",
    "required_if": "Lauks \":attribute\" field is required when :other is :value.",
    "required_unless": "Lauks \":attribute\" field is required unless :other is in :values.",
    "required_with": "Lauks \":attribute\" field is required when :values is present.",
    "required_with_all": "Lauks \":attribute\" field is required when :values are present.",
    "required_without": "Lauks \":attribute\" field is required when :values is not present.",
    "required_without_all": "Lauks \":attribute\" field is required when none of :values are present.",
    "same": "Lauks \":attribute\" and :other must match.",
    "size": {
      "numeric": "Lauks \":attribute\" must be :size.",
      "file": "Lauks \":attribute\" must be :size kilobytes.",
      "string": "Lauks \":attribute\" must be :size characters.",
      "array": "Lauks \":attribute\" must contain :size items."
    },
    "string": "Lauks \":attribute\" must be a string.",
    "timezone": "Lauks \":attribute\" must be a valid zone.",
    "unique": "Lauks \":attribute\" has already been taken.",
    "uploaded": "Lauks \":attribute\" failed to upload.",
    "url": "Lauks \":attribute\" format is invalid.",
    "uuid": "Lauks \":attribute\" must be a valid UUID.",
    "remote": "Lauks \":attribute\" field is invalid."
  }
}
