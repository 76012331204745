// @ts-ignore
import lv from './locales/lv'
import vueform from '@vueform/vueform/themes/vueform'
import {defineConfig} from '@vueform/vueform'
import axios from 'axios'
import CaptchaElement from './src/componets/CaptchaElement.vue'


axios.defaults.headers.common['X-CSRF-TOKEN'] = Joomla.getOptions('csrf.token', '')
export default defineConfig({
    theme: vueform,
    locales: {lv},
    locale: 'lv',
    axios,
    endpoints: {
        submit: {
            url: '?option=com_ajax&module=reactive_forms&format=raw',
            method: 'post'
        },
    },
    elements: [
        CaptchaElement,
    ],
})
