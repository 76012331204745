<script setup lang="ts">
import useVueformResponseErrors from 'vueform/src/VueformResponseErrors';
import {ref} from 'vue'
import type {Ref} from 'vue'
import type {JText} from 'joomla/joomla.interface';

let completed: Ref<boolean> = ref(false)

function success(): void {
  document.querySelector<HTMLElement>('#valuation-success')!.style.display = 'block'
  document.querySelector<HTMLElement>('#valuation')!.style.display = 'none'
  completed.value = true
}

const jText: JText = Joomla.JText
</script>

<template>
  <Vueform ref="form" v-if="!completed" form-key="valuation" @success="success"
           @response="useVueformResponseErrors as any" :add-classes="{GroupElement:{container: 'group-element'}}">

    <TextElement name="email" :label="jText._('MOD_REACTIVE_FORMS_LABEL_EMAIL')" input-type="email" :columns="{  default: 12,  md: 12}"
                 :rules="[
  {
    required: ['phone', '==', null],
    email:'debounce=1000'
  }
]"/>
    <TextElement name="phone" :add-class="{input: ['tel']}" :label="jText._('MOD_REACTIVE_FORMS_LABEL_PHONE')"
                 :rules="[
  {
    required: ['email', '==', null],
  }
]"
                 input-type="number"
                 :addons="{ }" :columns="{  default: 12,  md: 12}"/>
    <TextareaElement
        name="text"
        rules="required"
        :label="jText._('MOD_REACTIVE_FORMS_VALUATION_ABOUT_PROPERTY_LABEL')"
        :placeholder="jText._('MOD_REACTIVE_FORMS_VALUATION_ABOUT_PROPERTY_PLACEHOLDER')"
        :columns="{  default: 12,  md: 12}"
    />

    <CaptchaElement name="g_recaptcha_response"/>
    <ButtonElement
        name="submit"
        :button-label="jText._('MOD_REACTIVE_FORMS_VALUATION_CALCULATE_PRICE')"
        :submits="true"
        align="center"
        :add-class="{button: ['button']}"
    />
  </Vueform>
</template>
<style lang="scss">
input[type=number].tel {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
}
</style>
