import useVueFormCustom from './VueformCustom'

export default function useVueformResponseErrors(response: any, form: any): void {
    if (typeof response.data.errors === 'undefined') {
        return
    }
    const customForm = useVueFormCustom(form)
    form.clearMessages()
    // @ts-ignore
    response.data.errors.forEach((error: any) => {
        if (error.fieldName) {
            const field = customForm.findElementByName(error.fieldName)
            if (field) {
                field.messageBag.append(error.title, 'error')
            } else {
                form.messageBag.append(error.title)
            }
        } else {
            form.messageBag.append(error.title)
        }
    })
}
