<template>
  <ElementLayout>
    <template #element>
      <div class="g-recaptcha" id="g-recaptcha" :data-sitekey="key"></div>
    </template>
    <!-- Default element slots -->
    <template v-for="(component, slot) in elementSlots" #[slot]>
      <slot :name="slot" :el$="el$">
        <component :is="component" :el$="el$"/>
      </slot>
    </template>
  </ElementLayout>
</template>
<script lang="ts">
// @ts-nocheck
import {ref} from 'vue'
import {defineElement} from '@vueform/vueform'
import {onMounted} from 'vue'

export default defineElement({
  name: 'CaptchaElement',
  setup(props, {element}) {
    element.form$.value.on('submit', () => {
      element.value.value = document.querySelector<HTMLElement>('.g-recaptcha-response').value
    })
    onMounted(() => {
      try {
        grecaptcha.render('g-recaptcha') // If vue is too slow captcha is not rendered and we need to explicitly render
      } catch (e) {
      }
    })
    const defaultClasses = ref({
      container: '', // added automatically to the element's outermost DOM in ElementLayout
      input: 'form-text-input',
      input_danger: 'has-errors',
      $input: (classes, {isDanger}) => ([
        classes.input,
        isDanger ? classes.input_danger : null,
      ])
    })

    const key: string = Joomla.getOptions('configuration').captchaPublicKey

    return {
      defaultClasses,
      key,
    }
  }
})

</script>

<style lang="scss">
.form-text-input {
  border: 1px solid black;
  outline: none;
  width: 100%;

  &.has-errors {
    border: 1px solid red;
  }
}
</style>
